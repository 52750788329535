<template>
	<div>
		<button @click="identityVerification" :class="className" ref="authButton">
			<i v-if="hasIcon" class="ic-32 mobile"></i>{{ title }}
		</button>
		<form
			ref="km"
			name="reqKMCISForm"
			target="KMCISWindow"
			method="post"
			action="https://www.kmcert.com/kmcis/web/kmcisReq.jsp"
		>
			<input type="hidden" name="tr_cert" value="" />
			<input type="hidden" name="tr_url" :value="`${VUE_APP_API_URL}/kmc/success`" />
			<input type="hidden" name="tr_add" value="N" />
			<!-- <input type="hidden" name="ci" v-model="signup.ci" /> -->
			<input type="hidden" name="ci" value="" />
			<input type="hidden" name="phoneNo" value="" />
			<input type="hidden" name="birthDay" value="" />
		</form>
	</div>
</template>

<script>
import { kmcSignup } from '@/api/login';
import { IS_DEV } from '@/constants';

export default {
	name: 'IdentityVerificationButton',
	props: {
		codeType: {
			type: String,
			default: 'login',
		},
		className: {
			type: String,
			default: 'solid',
		},
		title: {
			type: String,
			default: '본인인증',
		},
		isSnsSign: {
			type: Boolean,
			default: false,
		},
		hasIcon: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			VUE_APP_API_URL: process.env.VUE_APP_API_URL,
			frontUrl: process.env.VUE_APP_FRONT_SERVER,
		};
	},
	created() {
		window.addEventListener('message', this.handleMessage);
		// if (this.isSnsSign) {
		// 	this.identityVerification();
		// }
	},
	destroyed() {
		window.removeEventListener('message', this.handleMessage);
	},
	methods: {
		handleMessage(event) {
			if (event.origin !== location.origin) return;
			if (event.data.ci && event.data.phoneNumber && event.data.birthday && event.data.name) {
				// console.log(event.data);
				this.$emit('complete', {
					name: event.data.name,
					ci: event.data.ci,
					phoneNumber: event.data.phoneNumber,
					birthday: event.data.birthday,
					email: event.data.email,
				});
			}
		},
		async identityVerification() {
			this.$emit('clearAuth');
			if (IS_DEV) {
				window.open(this.frontUrl + '/identityVerificationSuccess');
			} else {
				let encryptData = await kmcSignup(this.codeType);
				window.open(
					'',
					'KMCISWindow',
					'width=425, height=550, resizable=0, scrollbars=no, status=0, titlebar=0, toolbar=0, left=435, top=250',
				);
				this.$refs.km.tr_cert.value = encryptData.data;
				this.$refs.km.submit();
			}
		},
	},
};
</script>

<style scoped></style>
