import { DIFF_IDENTITY, NOT_MATCH_EMAIL_AND_IDENTITY, NOT_SIGNED } from '@/temlplates';
import mixinsAlert from '@/components/common/mixinsAlert';
import _ from 'lodash';
import { findId } from '../../api/login';

export const checkIdentity = {
	mixins: [mixinsAlert],
	methods: {
		findUserByIdentityVerification(data, loginId = null, successHandler, hideModalName = '') {
			// TODO:본인/이메일 인증
			let response = findId(data);
			response
				.then((resp) => {
					if ((!_.isEmpty(loginId) && loginId === resp.data[0].loginId) || _.isEmpty(loginId)) {
						successHandler(resp);
					} else {
						this.notMatchRequestEmail();
					}
				})
				.catch((error) => {
					if (_.isEmpty(loginId)) {
						this.notSigned(hideModalName);
					} else {
						this.notMatchRequestEmail();
					}
				});

			return response;
		},
		notSigned(hideModalName = '') {
			this.alert({
				contents: NOT_SIGNED,
				buttons: [
					{
						text: '회원가입',
						callback: () => {
							this.$router.replace('/signup');
							if (hideModalName) {
								this.$modal.hide(hideModalName);
							}
						},
					},
					{},
				],
			});
		},
		notMatchRequestEmail() {
			this.alert(NOT_MATCH_EMAIL_AND_IDENTITY);
		},
		updateUserByIdentityVerificationProfile(data, successHandler) {
			const userData = data.userData;
			const roleType = data.roleType;

			let response =
				roleType === 'SUPER ADMIN'
					? this.$store.dispatch('updateCustomerManagerIdentity', userData)
					: this.$store.dispatch('updateUserIdentity', userData);
			response
				.then((resp) => {
					console.log('into then');
					successHandler(resp);
				})
				.catch(() => {
					console.log('into catch');
					this.alert(DIFF_IDENTITY);
				});
		},
	},
};
