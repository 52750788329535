<template>
	<div class="container">
		<!-- contents area -->
		<div class="body-contents">
			<!-- 메뉴 제목 : body-contents의 타이틀은 메뉴 제목(default로 h2 사용)-->
			<h2 class="heading-1-b">내정보관리</h2>
			<!-- // 메뉴 제목-->
			<!-- 실제 컨텐츠 영역 -->
			<div class="tab-wrap">
				<!-- tab -->
				<!-- // tab -->
				<!-- tab contents 01 : 정보수정 -->
				<div class="tab-contents section-wrap gap64" id="testCont01">
					<!-- form -->
					<!-- section 0 : 프로필 -->
					<div class="section">
						<!-- cont -->
						<div class="contents">
							<!-- user profile -->
							<div class="user-profile">
								<div class="avatar">
									<!-- image -->
									<img v-if="$store.getters.hasProfile" :src="$store.state.login.currentUser.profileUrl" alt="" />
								</div>
								<!-- btn icon -->
								<button v-if="!$store.getters.hasProfile" class="icon line" @click="openFileTypeInput">
									<i class="ic-16 ic-edit"></i>
									<input
										type="file"
										ref="fileInput"
										:id="inputId"
										v-on:change="selectFile($event)"
										style="display: none"
									/>
								</button>
								<!-- btn text -->
								<button class="link-text" @click="deleteProfile">프로필 사진 삭제</button>
							</div>
						</div>
					</div>
					<!-- section 1 -->
					<div class="section">
						<h3 class="title">
							{{ this.currentUser.accountRoleType === 'SUPER ADMIN' ? '기업 회원 계정 정보' : '계정 정보' }}
						</h3>
						<div class="contents">
							<div class="group column vertical-form condensed">
								<div class="group row">
									<label class="form-label group row align-middle">
										<span class="txt">아이디</span>
										<template v-if="this.currentUser.accountRoleType === 'SUPER ADMIN'">
											<i class="ic-16 ic-info">
												<div class="tooltip-layer left">
													고객포털 아이디는 다우오피스 계약 및 서비스 이용 구매를 위해 기업을 대표하는 아이디로 변경이
													불가합니다.<br />
													필요시 이용하고 있는 멤버십 해지와 함께 고객포털 서비스 탈퇴 후 재가입이 필요합니다.
												</div>
											</i>
										</template>
									</label>
									<div class="form-value">
										<span class="txt">{{ currentUser.loginId }}</span>
									</div>
								</div>

								<div class="group row">
									<label class="form-label group row align-middle">
										<span class="txt">비밀번호</span>
										<i class="ic-16 ic-info">
											<div class="tooltip-layer left">
												사업자등록번호, 개업일자, 대표자성명은 사업자 진위 확인 필수값입니다. 반드시 입력 후 진위 확인을
												하세요.
											</div>
										</i>
									</label>
									<div class="form-value">
										<button
											v-if="!this.$store.getters.hasPassword"
											@click="handlePasswordInitOrChange"
											class="line mini"
										>
											비밀번호 생성
										</button>
										<button v-else @click="handlePasswordInitOrChange" class="line mini">비밀번호 변경</button>
									</div>
								</div>

								<div class="group row">
									<label class="form-label group row align-middle">
										<span class="txt">2차 인증</span>
										<span v-if="useSecondFactor" class="badge-state">사용중</span>
										<span v-else class="badge-state important">미사용</span>
									</label>
									<div class="form-value">
										<!-- 토글 -->
										<ToggleButton
											:on="useSecondFactor"
											v-on:toggleButton:click="
												() => {
													if (this.$store.getters.hasPassword) {
														this.updateTwoFactor();
													}
												}
											"
											:is-small="true"
										></ToggleButton>
										<span v-if="useSecondFactor" class="description"
											>2차 인증 사용 설정일 : {{ this.currentUser.twoFactorUpdateAt }}</span
										>
										<span v-else class="description"
											>로그인 시점에 기본정보(담당자 기본정보)에 등록한 이메일로 일회용 인증번호를 전송합니다.</span
										>
									</div>
								</div>
							</div>
						</div>
					</div>
					<!-- section 2 -->

					<div class="section">
						<h3 class="title">
							{{ this.currentUser.accountRoleType === 'SUPER ADMIN' ? '담당자정보' : '기본정보' }}
							<IdentityVerificationButton
								code-type="profile"
								:title="'기본 정보 수정'"
								:className="'line mini'"
								v-on:complete="handleComplete"
							/>
							<span class="description"
								>이름, 휴대전화, 생년월일 변경이 필요하신 경우 본인인증을 완료하시면 자동으로 일괄 변경됩니다.</span
							>
						</h3>
						<div class="contents">
							<div class="group column vertical-form condensed">
								<div class="group row">
									<label class="form-label group row align-middle">
										<span class="txt">이름</span>
										<i class="ic-16 ic-info">
											<div class="tooltip-layer left">
												개명으로 이름이 변경된 경우 또는 휴대전화 번호 변경이 필요한 경우는 이동통신사 및 신용평가기관에
												모두 변경된 내용으로 등록되어 있는지 확인 후 진행해주세요.
											</div>
										</i>
									</label>
									<div class="form-value">
										<span class="txt">{{ name }}</span>
									</div>
								</div>
								<div class="group row">
									<label class="form-label">
										<span class="txt">생년월일</span>
									</label>
									<div class="form-value">
										<span class="txt">{{ this.$store.getters.getBirth }}</span>
									</div>
								</div>
								<div class="group row">
									<label class="form-label group row align-middle">
										<span class="txt">휴대전화</span>
										<!-- <i class="ic-16 ic-info">
											<div class="tooltip-layer left">
												휴대전화 번호 변경된 경우, 이동통신사 및 신용평가기관 모두 변경된 내용으로 등록되어 있는지 확인
												후 진행해 주세요.
											</div>
										</i> -->
									</label>
									<div class="form-value">
										<span class="txt">{{ this.$store.getters.getPhone }}</span>
									</div>
								</div>

								<div class="group row">
									<label class="form-label group row align-middle">
										<span class="txt">이메일</span>
									</label>
									<div class="form-value">
										<span class="txt mr-8">{{ currentUser.email }}</span>
										<button @click="handleEmail" class="line mini">이메일 변경</button>
									</div>
								</div>
							</div>
						</div>
					</div>
					<!-- section 3 -->
					<div class="section">
						<h3 class="title">
							추가 정보
							<button class="line mini" @click="handleUpdateExtra">추가정보 수정</button>
							<span class="description"
								>직급, 유선전화 입력/변경이 필요하신 경우 추가정보 수정을 완료하시면 자동으로 일괄 변경됩니다.</span
							>
						</h3>
						<div class="contents">
							<div class="group column vertical-form condensed">
								<div class="group row">
									<label class="form-label group row align-middle">
										<span class="txt">소속 회사</span>
										<i class="ic-16 ic-info">
											<div class="tooltip-layer left">
												기업계정으로 로그인 후 사용자관리에서 <br />
												초대/제외로 소속회사 변경이 가능합니다.
											</div>
										</i>
									</label>
									<div class="form-value">
										<span class="txt" :class="{ description: !hasCompanyInfo }">{{
											this.companyInfo.corporateName ?? '소속회사가 없습니다.'
										}}</span>
										<button
											v-if="hasCompanyInfo"
											class="ml-16 link-text"
											@click="$router.push('/user/myStore/CompanyInfo')"
										>
											회사법인정보관리로 이동
										</button>
									</div>
								</div>
								<!--<div class="group row" v-show="!isSuperAdmin">
									<label class="form-label group row align-middle">
										<span class="txt">회원등급 / 권한</span>
										<i class="ic-16 ic-info">
											<div class="tooltip-layer left">
												권한은 준회원일 경우(서비스 개설 전) 노출되지 <br />
												않으며, 정회원으로 등급이 변경 후 사용자에게 <br />
												부여된 권한이 노출됩니다.
											</div>
										</i>
									</label>
									<div class="form-value">
										<span class="txt" v-if="isMembership"> 정회원 / {{ currentUser.accountRoleType }} </span>
										<span class="txt" v-else> 준회원 / - </span>
									</div>
								</div> -->
								<!-- <div class="group row">
									<label class="form-label">
										<span class="txt">직급</span>
									</label>
									<div class="form-value">
										<span class="txt">{{
											this.$store.getters.getExtra.rank.length !== 0 ? this.$store.getters.getExtra.rank : '-'
										}}</span>
									</div>
								</div> -->
								<div class="group row">
									<label class="form-label">
										<span class="txt">유선 전화</span>
									</label>
									<div class="form-value">
										<span class="txt">{{
											this.$store.getters.getExtra.tel?.length !== 0 ? this.$store.getters.getExtra.tel : '-'
										}}</span>
									</div>
								</div>
							</div>
						</div>
					</div>
					<!-- section 4 -->
					<div class="section" v-if="!isSuperAdmin">
						<h3 class="title">
							연동 관리
							<span class="description">다른 서비스 계정과 연결해 간편하게 로그인 하세요.</span>
						</h3>
						<div class="contents">
							<p class="body-2-r mb-20">
								개인정보 보호를 위해 공용 PC에서 사용하실 경우 계정의 로그아웃 상태를 반드시 확인해주세요.
							</p>
							<ul class="toggle-list">
								<!--								<li class="group">
									<div class="group text">
										<div class="sns-title">
											<i class="ic-32 ic-logo daou">logo</i>
											다우오피스
										</div>
										<p class="text-additional">{{ snsAccountResult('daou') }}</p>
									</div>
									&lt;!&ndash; 토글 &ndash;&gt;
									<ToggleButton
										:on="useNaver"
										v-on:toggleButton:click="
											() => {
												if (this.isSuperAdmin) return;
												toggleChange('daou');
											}
										"
										:is-small="true"
									></ToggleButton>
								</li>-->
								<li class="group">
									<div class="group text">
										<div class="sns-title">
											<i class="ic-32 ic-logo naver">logo</i>
											네이버
										</div>
										<p class="text-additional">{{ snsAccountResult('naver') }}</p>
									</div>
									<!-- 토글 -->
									<ToggleButton
										:on="useNaver"
										v-on:toggleButton:click="
											() => {
												if (this.isSuperAdmin) return;
												toggleChange('naver');
											}
										"
										:is-small="true"
									></ToggleButton>
								</li>
								<li class="group">
									<div class="group text">
										<div class="sns-title disabled">
											<i class="ic-32 ic-logo kakao">logo</i>
											카카오
										</div>
										<p class="text-additional">{{ snsAccountResult('kakao') }}</p>
									</div>
									<!-- 토글 -->
									<ToggleButton
										:on="useKakao"
										v-on:toggleButton:click="
											() => {
												if (this.isSuperAdmin) return;
												toggleChange('kakao');
											}
										"
										:is-small="true"
										:disabled="true"
									></ToggleButton>
								</li>
							</ul>
						</div>
					</div>
					<!-- section 5 -->
					<div class="section">
						<h3 class="title">약관 및 정책 동의 현황</h3>
						<!-- cont -->
						<div class="contents">
							<!-- grid -->
							<div class="group consent-form row">
								<ul class="check-list conditions-list">
									<li>
										<h4>
											서비스 이용 약관동의
											<em class="text-caution">(필수)</em>
										</h4>
									</li>
									<!-- selected disabled -->
									<li v-for="agreement in this.$store.getters.getRequiredTermsList" :key="agreement.termsAgreementId">
										<div class="group-chk disabled">
											<input type="checkbox" :id="agreement.termsAgreementId" checked="" disabled="" />
											<label :for="agreement.termsAgreementId" class="label"> {{ agreement.termsName }} </label>
											<p class="text-additional mr-8">
												{{ agreement.agreed ? agreement.agreeDate + ' 동의' : '미동의' }}
											</p>
										</div>
										<button class="line mini" @click="openTermsLayer(agreement.termsId, agreement.termsName)">
											내용보기
										</button>
									</li>
								</ul>
								<ul class="check-list conditions-list">
									<li>
										<h4>
											이벤트 및 상품 동의
											<em class="text-additional">(선택)</em>
										</h4>
									</li>
									<!-- selectable -->
									<li
										v-for="agreement in this.$store.getters.getNotRequiredTermsList"
										:key="agreement.termsAgreementId"
									>
										<div class="group-chk">
											<input
												type="checkbox"
												:id="agreement.termsAgreementId"
												@click="agreeTerms($event, agreement.termsAgreementId)"
												:checked="agreement.agreed"
											/>
											<label :for="agreement.termsAgreementId" class="label"> {{ agreement.termsName }} </label>
											<p class="text-additional mr-8">
												{{ agreement.agreed ? agreement.agreeDate + ' 동의' : '미동의' }}
											</p>
										</div>
										<button class="line mini" @click="openTermsLayer(agreement.termsId, agreement.termsName)">
											내용보기
										</button>
									</li>
									<!-- selectable -->
								</ul>
							</div>
						</div>
					</div>
					<!-- section 5 -->
					<div class="section" v-if="!isSuperAdmin">
						<h3 class="title">회원탈퇴</h3>
						<!-- cont -->
						<div class="contents">
							<button @click="handleRejectUser" class="line mini">고객포털 탈퇴하기</button>
						</div>
					</div>
					<!-- // 정보 수정 -->
				</div>
				<!-- contents 02 : 연동관리 -->
				<!--				<div class="tab-contents contents-wrap" :class="{ blind: activeTab !== 'integration' }" id="testCont02">-->
				<!--					&lt;!&ndash; form &ndash;&gt;-->
				<!--					<form>-->
				<!--						<fieldset>-->
				<!--							&lt;!&ndash; section 1 &ndash;&gt;-->
				<!--							<div class="section mb-56">-->
				<!--								<div class="contents">-->
				<!--									<p class="description mb-20">-->
				<!--										다른 서비스 계정과 연결해 간편하게 로그인 하세요.<br />-->
				<!--										개인정보 보호를 위해 공용 PC에서 사용하실 경우 계정의 로그아웃 상태를 반드시 확인해주세요.-->
				<!--									</p>-->

				<!--									<ul class="toggle-list">-->
				<!--										&lt;!&ndash;										<li class="group">&ndash;&gt;-->
				<!--										&lt;!&ndash;											<div class="group text">&ndash;&gt;-->
				<!--										&lt;!&ndash;												<div class="sns-title">&ndash;&gt;-->
				<!--										&lt;!&ndash;													<i class="ic-32 ic-logo daou">logo</i>&ndash;&gt;-->
				<!--										&lt;!&ndash;													다우오피스&ndash;&gt;-->
				<!--										&lt;!&ndash;												</div>&ndash;&gt;-->
				<!--										&lt;!&ndash;												<p class="text-additional">{{ snsAccountResult('daou') }}</p>&ndash;&gt;-->
				<!--										&lt;!&ndash;											</div>&ndash;&gt;-->
				<!--										&lt;!&ndash;											&lt;!&ndash; 토글 &ndash;&gt;&ndash;&gt;-->
				<!--										&lt;!&ndash;											&lt;!&ndash; TODO: daouOffice 로그인 시, snsType 변경&ndash;&gt;&ndash;&gt;-->
				<!--										&lt;!&ndash;											<ToggleButton&ndash;&gt;-->
				<!--										&lt;!&ndash;												:on="useDaouOffice"&ndash;&gt;-->
				<!--										&lt;!&ndash;												v-on:toggleButton:click="&ndash;&gt;-->
				<!--										&lt;!&ndash;													() => {&ndash;&gt;-->
				<!--										&lt;!&ndash;														toggleChange('daou');&ndash;&gt;-->
				<!--										&lt;!&ndash;													}&ndash;&gt;-->
				<!--										&lt;!&ndash;												"&ndash;&gt;-->
				<!--										&lt;!&ndash;												:is-small="true"&ndash;&gt;-->
				<!--										&lt;!&ndash;											></ToggleButton>&ndash;&gt;-->
				<!--										&lt;!&ndash;										</li>&ndash;&gt;-->
				<!--										<li class="group">-->
				<!--											<div class="group text">-->
				<!--												<div class="sns-title">-->
				<!--													<i class="ic-32 ic-logo naver">logo</i>-->
				<!--													네이버-->
				<!--												</div>-->
				<!--												<p class="text-additional">{{ snsAccountResult('naver') }}</p>-->
				<!--											</div>-->
				<!--											&lt;!&ndash; 토글 &ndash;&gt;-->
				<!--											<ToggleButton-->
				<!--												:on="useNaver"-->
				<!--												v-on:toggleButton:click="-->
				<!--													() => {-->
				<!--														toggleChange('naver');-->
				<!--													}-->
				<!--												"-->
				<!--												:is-small="true"-->
				<!--											></ToggleButton>-->
				<!--										</li>-->
				<!--										<li class="group">-->
				<!--											<div class="group text">-->
				<!--												<div class="sns-title">-->
				<!--													<i class="ic-32 ic-logo kakao">logo</i>-->
				<!--													카카오-->
				<!--												</div>-->
				<!--												<p class="text-additional">{{ snsAccountResult('kakao') }}</p>-->
				<!--											</div>-->
				<!--											&lt;!&ndash; 토글 &ndash;&gt;-->
				<!--											<ToggleButton-->
				<!--												:on="useKakao"-->
				<!--												v-on:toggleButton:click="-->
				<!--													() => {-->
				<!--														toggleChange('kakao');-->
				<!--													}-->
				<!--												"-->
				<!--												:is-small="true"-->
				<!--											></ToggleButton>-->
				<!--										</li>-->
				<!--									</ul>-->
				<!--								</div>-->
				<!--							</div>-->
				<!--						</fieldset>-->
				<!--					</form>-->
				<!--				</div>-->
			</div>
		</div>
	</div>
</template>

<script>
import ToggleButton from '@/components/common/ToggleButton.vue';
import IdentityVerificationButton from '@/components/login/IdentityVerificationButton';
import { deleteSnsLogin } from '@/api/user';
import mixinsAlert from '@/components/common/mixinsAlert';
import { getTermDetail } from '@/api/customer';
import termsTemplate from '@/components/login/TermsDetailLayer';
import { DEFAULT_MODAL_STYLE, SIGNUP_MODAL_STYLE, TERMS_MODAL_STYLE } from '@/utils/constants';
import { ALREADY_SIGNED, DISAGREE_TERMS } from '@/temlplates';
import { checkIdentity } from '@/components/login/mixinsFindUser';
import updateExtraLayer from '@/components/user/updateExtraLayer';
import UpdatePasswordLayer from '@/components/user/updatePasswordLayer';
import { attach } from '@/components/common/mixinsAttach';
import updateEmailLayer from '@/components/user/updateEmailLayer.vue';
import _ from 'lodash';
import { findId } from '@/api/login';

export default {
	name: 'Profile',
	mixins: [mixinsAlert, checkIdentity, attach],
	components: { IdentityVerificationButton, ToggleButton },
	data() {
		const { currentUser } = this.$store.state.login;
		return {
			currentUser,
			backUrl: process.env.VUE_APP_API_URL,
			frontUrl: process.env.VUE_APP_FRONT_SERVER,
			isChange: false,
			useSecondFactor: this.$store.getters.isTwoFactor,
			AccountLink: currentUser.snsMap,
			useDaouOffice: currentUser.snsMap.daou ? true : false,
			useNaver: currentUser.snsMap.naver ? true : false,
			useKakao: currentUser.snsMap.kakao ? true : false,
			name: currentUser.name,
			rank: currentUser.rank,
			tel: currentUser.tel,
			email: currentUser.email,
			companyInfo: this.$store.state.apply.companyInfo,
			inputId: this._uid + 'upload',
		};
	},
	async created() {
		await this.$store.dispatch('fetchUserProfile');
		const response = await this.$store.dispatch('fetchCustomerInfo');
		this.companyInfo = response.data?.companyInfo;
	},
	computed: {
		getConnectCnt() {
			let cnt = 0;
			if (this.useDaouOffice) cnt++;
			if (this.useNaver) cnt++;
			if (this.useKakao) cnt++;
			return cnt;
		},
		hasCompanyInfo() {
			return !_.isEmpty(this.companyInfo);
		},
		isMembership() {
			return this.$store.getters['isMemberShipUser'];
		},
		isSuperAdmin() {
			return this.$store.getters['isSuperAdmin'];
		},
	},
	methods: {
		async handleUpdateExtra() {
			this.$modal.show(
				updateExtraLayer,
				{ rank: this.$store.getters.getExtra.rank, tel: this.$store.getters.getExtra.tel },
				Object.assign({}, DEFAULT_MODAL_STYLE, { name: 'updateExtraLayer', clickToClose: false }),
			);
		},
		async updateTwoFactor() {
			if (this.useSecondFactor) {
				this.alert({
					contents: `2차인증을 해지하시겠습니까?`,
					buttons: [
						{
							text: '확인',
							callback: async () => {
								let data = { accountTwoFactorType: 'NONE' };
								await this.$store.dispatch('updateUserTwoFactor', data).then(() => {
									this.useSecondFactor = false;
								});
							},
						},
						{
							text: '취소',
							callback: () => {
								this.$emit('close');
							},
						},
					],
				});
			} else {
				let data = { accountTwoFactorType: 'EMAIL' };
				const response = await this.$store.dispatch('updateUserTwoFactor', data);
				this.useSecondFactor = true;
				this.currentUser.twoFactorUpdateAt = response.data.twoFactorUpdateAt;
			}
		},
		async handleComplete(data) {
			let userData = {
				name: data.name,
				ci: data.ci,
				phoneNumber: data.phoneNumber,
				birthDay: data.birthday,
			};
			const accountRoleType = this.currentUser.accountRoleType;
			if (accountRoleType !== 'SUPER ADMIN' && (await this.canNotUpdateUser(userData))) {
				return;
			}

			this.updateUserByIdentityVerificationProfile({ userData, roleType: accountRoleType }, (resp) => {
				this.name = resp.data.name;
				this.$toasted.show('기본 정보 수정 본인인증에 성공했습니다.');
			});
		},
		async canNotUpdateUser(userData) {
			console.log('canNotUpdate User entire');
			try {
				const response = await findId({
					name: userData.name,
					ci: userData.ci,
					phoneNumber: userData.phoneNumber,
					birthDay: userData.birthDay,
					loginId: this.currentUser.loginId,
				});

				if (response.data.length > 1) {
					this.$toasted.show('기본 정보 수정 본인인증에 실패했습니다.');
					return true;
				} else {
					return false;
				}
			} catch (e) {
				// this.moveToStep(1);
				console.log(e);
				this.$toasted.show('기본 정보 수정 본인인증에 실패했습니다.');
				return true;
			}
		},
		agreeTerms(event, id) {
			let res = event.target.checked;
			let response = Promise.resolve();

			if (!res) {
				event.preventDefault();
				this.alert({
					contents: DISAGREE_TERMS,
					buttons: [
						{
							text: '확인',
							callback: () => {
								const data = {
									termsAgreementId: id,
									agreed: res,
								};
								response = this.$store.dispatch('updateUserTerms', data);
								this.$toasted.show('약관 동의를 철회하였습니다.');
							},
						},
						{
							text: '취소',
							callback: () => {
								this.$emit('close');
							},
						},
					],
				});
			} else {
				const data = {
					termsAgreementId: id,
					agreed: res,
				};
				response = this.$store.dispatch('updateUserTerms', data);
				this.$toasted.show('약관 동의가 완료되었습니다.');
			}
		},
		handleRejectUser() {
			if (this.isSuperAdmin) return;
			this.$router.push({ name: 'Secession' });
		},
		handlePasswordInitOrChange() {
			this.$modal.show(
				UpdatePasswordLayer,
				{ hasPassword: this.$store.getters.hasPassword },
				Object.assign({}, DEFAULT_MODAL_STYLE, { name: 'UpdatePasswordLayer', clickToClose: false }),
			);
		},
		snsAccountResult(snsType) {
			return this.getSnsObject(snsType)
				? this.getSnsObject(snsType).createdAt + ' 동의'
				: '연결된 계정 정보가 없습니다.';
		},
		getSnsObject(snsType) {
			return this.AccountLink[snsType];
		},
		deleteSnsTypeConnect(snsType) {
			if (snsType === 'daou') this.useDaouOffice = false;
			if (snsType === 'naver') this.useNaver = false;
			if (snsType === 'kakao') this.useKakao = false;
			this.AccountLink[snsType] = null;
		},
		toggleChange(snsType) {
			if (!this.getSnsObject(snsType)) {
				location.href =
					this.backUrl + '/oauth2/authorization/' + snsType + '?redirect_uri=' + this.frontUrl + '/oauth/redirect/sns';
			} else if (this.getSnsObject(snsType)) {
				// TODO: 연동을 해지하시겠습니까?
				if (!this.$store.getters.hasPassword && this.getConnectCnt === 1) {
					this.alert('비밀번호가 설정되지 않은 계정입니다.</br> 로그인을 위해 1개 이상의 계정 연동이 필요합니다.');
				} else {
					const snsAccount = this.getSnsObject(snsType);

					let id = snsAccount.accountSnsId;
					this.alert({
						contents: '연동을 해지하시겠습니까?',
						buttons: [
							{
								callback: async () => {
									this.deleteSnsTypeConnect(snsType);
									let response = deleteSnsLogin({ id: id });
									response.catch(() => {
										console.log('/profile account link error');
									});
								},
							},
							{},
						],
					});
				}
			}
		},
		async deleteProfile() {
			this.alert({
				contents: '프로필 사진을 삭제하시겠습니까?',
				buttons: [
					{
						callback: async () => {
							await this.$store.dispatch('deleteUserProfileUrl');
						},
					},
					{},
				],
			});
		},
		async openTermsLayer(termsId, termsName) {
			const response = await getTermDetail(termsId);
			// 주문 레이어 사용
			this.$modal.show(
				termsTemplate,
				{
					termsTitle: termsName,
					termsContent: response.data.termsContent,
				},
				Object.assign({}, TERMS_MODAL_STYLE, { name: 'termsTemplate', clickToClose: false }),
			);
		},
		setAttaches(fileInfo) {
			const profileUrl = `${process.env.VUE_APP_FILE_API_URL}/attachment/${fileInfo.attachKey}`;
			this.$store.dispatch('updateUserProfileUrl', profileUrl);
		},
		handleCompanyInfo() {
			this.$router.push('/user/myStore/CompanyInfo');
		},
		async handleEmail() {
			await this.openLayerChangeEmail();
			console.log('change Email');
		},
		openLayerChangeEmail() {
			this.$modal.show(
				updateEmailLayer,
				{
					originalEmail: this.email,
				},
				Object.assign({}, SIGNUP_MODAL_STYLE, { name: 'updateEmailLayer', clickToClose: false }),
				{
					'before-close': this.changeEmail,
				},
			);
		},
		changeEmail() {
			this.currentUser.email = this.$store.state.login.currentUser.email;
		},
	},
};
</script>

<style scoped>
@import '../../../src/assets/css/common.css';
</style>
