<template>
	<!-- 비밀번호 생성 -->
	<div>
		<div class="header">
			<!-- // .lay_header -> .header -->
			<h1 v-if="hasPassword">비밀번호 변경</h1>
			<h1 v-else>비밀번호 생성</h1>
			<button class="icon" @click="handleClose">
				<i class="ic-24 ic-close"></i>
				close
			</button>
		</div>
		<!--  contents-wrap  -->
		<div class="contents-wrap">
			<p v-if="hasPassword" class="body-2-r mb-24">
				다우오피스 고객포털은 주기적인 비밀번호 변경을 권장합니다.<br />안전한 비밀번호로 내 정보를 보호하세요.
			</p>
			<p v-else class="body-2-r mb-24">
				비밀번호를 설정하시면<br />간편가입 계정 이메일과 비밀번호로 로그인이 가능합니다.
			</p>
			<ul class="inp-list">
				<li v-if="hasPassword" :class="{ invalid: isInvalidOldPassword }">
					<div class="group-inp rgt-ic-16">
						<label for="" class="label">기존 비밀번호</label>
						<input
							ref="oldPasswordInput"
							:type="isOldPasswordVisible ? 'text' : 'password'"
							id="oldPassword"
							class=""
							maxlength="50"
							placeholder="기존 비밀번호를 입력해주세요."
							v-model="oldPassword"
							@focus="
								() => {
									this.oldPasswordReason = this.OLD_PASSWORD_FAIL();
									this.isInvalidOldPassword = true;
								}
							"
							@blur="oldPasswordConfirm"
						/>
						<i
							@click="toggleOldPassword"
							class="ic-16"
							:class="{ 'ic-view-off': !isOldPasswordVisible, 'ic-view': isOldPasswordVisible }"
						></i>
						<p v-if="oldPasswordReason" class="text-detail" :class="[isInvalidOldPassword ? 'invalid' : 'valid']">
							<i class="ic-16" :class="[isInvalidOldPassword ? 'ic-invalid' : 'ic-valid']"></i>{{ oldPasswordReason }}
						</p>
					</div>
				</li>
				<li class="group-inp rgt-ic-16" :class="{ invalid: isInvalidNewPassword }">
					<div class="group-inp rgt-ic-16">
						<label for="" class="label">새 비밀번호</label>
						<input
							ref="newPasswordInput"
							:type="isNewPasswordVisible ? 'text' : 'password'"
							id="newPassword"
							class=""
							maxlength="50"
							placeholder="새 비밀번호를 입력해주세요. (8~16자 이내, 숫자+영문자+특수문자)"
							v-model="newPassword"
							v-on:input="validatePassword"
							@keyup="checkPasswordLog(setResultNewPassword, 1000, $event)"
						/>
						<i
							@click="toggleNewPassword"
							class="ic-16"
							:class="{ 'ic-view-off': !isNewPasswordVisible, 'ic-view': isNewPasswordVisible }"
						></i>
						<p v-if="newPasswordReason" class="text-detail" :class="[isInvalidNewPassword ? 'invalid' : 'valid']">
							<i class="ic-16" :class="[isInvalidNewPassword ? 'ic-invalid' : 'ic-valid']"></i>{{ newPasswordReason }}
						</p>
					</div>
				</li>
				<li :class="{ invalid: isInvalidNewPasswordConfirm }">
					<div class="group-inp rgt-ic-16">
						<label for="" class="label">새 비밀번호 확인</label>
						<input
							ref="newPasswordConfirmInput"
							:type="isNewPasswordConfirmVisible ? 'text' : 'password'"
							id="newPasswordConfirm"
							class=""
							maxlength="50"
							placeholder="새 비밀번호를 한번 더 입력해주세요."
							v-model="newPasswordConfirm"
							v-on:input="validatePasswordConfirm"
						/>
						<i
							@click="toggleNewPasswordConfirm"
							class="ic-16"
							:class="{ 'ic-view-off': !isNewPasswordConfirmVisible, 'ic-view': isNewPasswordConfirmVisible }"
						></i>
						<p
							v-if="newPasswordConfirmReason"
							class="text-detail"
							:class="[isInvalidNewPasswordConfirm ? 'invalid' : 'valid']"
						>
							<i class="ic-16" :class="[isInvalidNewPasswordConfirm ? 'ic-invalid' : 'ic-valid']"></i
							>{{ newPasswordConfirmReason }}
						</p>
					</div>
				</li>
			</ul>
		</div>
		<div class="btn-area group-btn">
			<button class="line" @click="handleClose">취소</button>
			<button class="solid" @click="changePassword">저장</button>
		</div>
	</div>
</template>

<script>
import { checkOldPassword, checkPasswordLog } from '@/api/login';
import _ from 'lodash';
import mixinsAlert from '@/components/common/mixinsAlert';
import {
	INVALID_PASSWORD_CONTENT,
	INVALID_PASSWORD_LENGTH_CHARACTER,
	OLD_PASSWORD_FAIL,
	PASSWORD_MATCH,
	PASSWORD_MATCH_FAIL,
	RECENT_THREE_OLD_PASSWORD,
	VALID_PASSWORD,
} from '@/temlplates';
import { password_validation } from '@/utils/utils';

export default {
	name: 'updatePassword',
	mixins: [mixinsAlert],
	props: ['hasPassword'],
	data() {
		const { currentUser } = this.$store.state.login;
		return {
			oldPassword: '',
			isOldPasswordVisible: false,
			oldPasswordReason: '',
			newPassword: '',
			isNewPasswordVisible: false,
			newPasswordReason: '',
			newPasswordConfirm: '',
			isNewPasswordConfirmVisible: false,
			newPasswordConfirmReason: '',
			isInvalidOldPassword: null,
			isInvalidNewPassword: null,
			isInvalidNewPasswordConfirm: null,
			currentUser,
			email: currentUser.email,
			loginId: currentUser.loginId,
			phoneNumber: currentUser.phoneNumber,
			birthDay: currentUser.birthDay,
		};
	},
	methods: {
		OLD_PASSWORD_FAIL() {
			return OLD_PASSWORD_FAIL;
		},
		toggleNewPasswordConfirm() {
			this.newPasswordConfirm = this.$refs.newPasswordConfirmInput.value;
			this.isNewPasswordConfirmVisible = !this.isNewPasswordConfirmVisible;
		},
		toggleNewPassword() {
			this.newPassword = this.$refs.newPasswordInput.value;
			this.isNewPasswordVisible = !this.isNewPasswordVisible;
		},
		toggleOldPassword() {
			this.oldPassword = this.$refs.oldPasswordInput.value;
			this.isOldPasswordVisible = !this.isOldPasswordVisible;
		},
		async oldPasswordConfirm() {
			const response = await checkOldPassword({
				password: this.oldPassword,
			});

			if (response.data.passwordCheck) {
				this.oldPasswordReason = PASSWORD_MATCH;
				this.isInvalidOldPassword = false;
			} else {
				this.oldPasswordReason = OLD_PASSWORD_FAIL;
				this.isInvalidOldPassword = true;
			}
		},
		validatePassword(e) {
			const password = e.currentTarget.value;

			if (!password_validation.checkLengthAndCharacter(password)) {
				this.isInvalidNewPassword = true;
				this.newPasswordReason = INVALID_PASSWORD_LENGTH_CHARACTER;
			} else if (
				!password_validation.checkIdentityCharacters(
					password,
					this.phoneNumber,
					this.birthDay,
					this.email,
					this.loginId,
				) ||
				!password_validation.checkRepeatedCharacters(password) ||
				!password_validation.checkConsecutiveCharacters(password)
			) {
				this.isInvalidNewPassword = true;
				this.newPasswordReason = INVALID_PASSWORD_CONTENT;
			} else {
				this.isInvalidNewPassword = false;
				this.newPasswordReason = VALID_PASSWORD;
			}

			if (this.newPasswordConfirm.length !== 0) {
				this.validatePasswordConfirm();
			}
		},
		checkPasswordLog: _.debounce(async (setResultNewPassword, delay, e) => {
			const password = e.target.value;
			const response = await checkPasswordLog({
				password: password,
			});
			setResultNewPassword(response.data.passwordCheck);
		}, 1000),
		setResultNewPassword(passwordCheck) {
			if (passwordCheck) {
				this.newPasswordReason = RECENT_THREE_OLD_PASSWORD;
				this.isInvalidNewPassword = true;
			}
		},
		validatePasswordConfirm() {
			if (this.newPassword !== this.newPasswordConfirm) {
				this.newPasswordConfirmReason = PASSWORD_MATCH_FAIL;
				this.isInvalidNewPasswordConfirm = true;
			} else {
				this.newPasswordConfirmReason = PASSWORD_MATCH;
				this.isInvalidNewPasswordConfirm = false;
			}
		},
		allValidationCheck() {
			if (this.hasPassword && (!this.oldPassword || this.isInvalidOldPassword)) {
				if (!this.isInvalidOldPassword) {
					this.oldPasswordReason = OLD_PASSWORD_FAIL;
					this.isInvalidOldPassword = true;
				}
				this.$refs.oldPasswordInput.focus();
			} else if (!this.newPassword || this.isInvalidNewPassword) {
				if (!this.newPassword) {
					this.newPasswordReason = INVALID_PASSWORD_LENGTH_CHARACTER;
					this.isInvalidNewPassword = true;
				}
				this.$refs.newPasswordInput.focus();
			} else if (
				!this.newPasswordConfirm ||
				this.isInvalidNewPasswordConfirm ||
				this.newPassword !== this.newPasswordConfirm
			) {
				if (!this.newPasswordConfirm) {
					this.newPasswordConfirmReason = PASSWORD_MATCH_FAIL;
					this.isInvalidNewPasswordConfirm = true;
				}
				this.$refs.newPasswordConfirmInput.focus();
			}

			return !this.isInvalidOldPassword && !this.isInvalidNewPassword && !this.isInvalidNewPasswordConfirm;
		},
		changePassword() {
			if (!this.allValidationCheck()) {
				return;
			}

			let data = {};
			let response = Promise.resolve();

			if (this.hasPassword) {
				data = {
					oldPassword: this.oldPassword,
					newPassword: this.newPassword,
					checkPassword: this.newPasswordConfirm,
				};
				response = this.$store.dispatch('changeUserPassword', data, this.hasPassword);
			} else {
				data = { password: this.newPassword, checkPassword: this.newPasswordConfirm };
				response = this.$store.dispatch('initUserPassword', data, this.hasPassword);
			}

			response
				.then(() => {
					this.$toasted.show('저장이 완료되었습니다.');
					this.$emit('close');
				})
				.catch(() => {
					this.$toasted.show('저장에 실패했습니다.');
				});
		},
		handleClose() {
			this.$emit('close');
		},
	},
};
</script>

<style scoped></style>
