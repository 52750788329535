<template>
	<!-- 추가정보 수정 -->
	<div>
		<div class="header">
			<h1 class="heading-3-b">이메일 변경</h1>
			<button class="icon" @click="handleClose">
				<i class="ic-24 ic-close"></i>
				close
			</button>
		</div>
		<!--  contents-wrap  -->
		<div class="contents-wrap">
			<ul class="inp-list">
				<li>
					<div class="group-inp" :class="{ invalid: isInvalidEmail }">
						<label class="label asterisk">이메일</label>
						<input
							v-model="email"
							v-on:input="validateEmail"
							v-on:blur="validateDuplicateEmail"
							ref="email"
							type="email"
							id="email"
							maxlength="50"
							value=""
							placeholder="인증을 진행할 이메일을 입력해주세요."
						/>
						<p v-if="emailReason" class="text-detail" :class="[isInvalidEmail ? 'invalid' : 'valid']">
							<i class="ic-16" :class="[isInvalidEmail ? 'ic-invalid' : 'ic-valid']"></i>{{ emailReason }}
						</p>
					</div>
				</li>
			</ul>

			<div class="btn-area pr-0 pl-0 pt-16 pb-24" v-if="!isCodeVisible">
				<button @click="sendEmailAuth" class="solid full">인증코드 보내기</button>
			</div>
			<div class="btn-area pr-0 pl-0 pt-8 pb-32" v-else>
				<button @click="sendEmailAuth" class="line full">인증코드 재발급</button>
			</div>
			<div class="message-balloon mb-12" v-if="this.twofactorResult">{{ this.twofactorResult }}</div>
			<div class="group col1 full">
				<div v-if="isCodeVisible" class="group-inp">
					<label for="testButtonInput4" class="label blind">인증코드</label>
					<div class="group-inp-btn" id="testTextTime2">
						<input v-model="authCode" type="text" id="testButtonInput4" class="" value="" />
						<Countdown ref="countdown"></Countdown>
					</div>
				</div>
			</div>
			<div v-if="isCodeVisible" class="btn-area pr-0 pl-0 pt-16 pb-24">
				<button @click="handleConfirmEmail" class="solid full">인증코드 확인</button>
			</div>
		</div>
	</div>
</template>

<script>
import { checkEmail } from '@/plugins/custom/modules/validator';
import { sendMailUpdateUserEmail } from '@/api/user';
import { ALREADY_USED_EMAIL, INVALID_EMAIL, VALID_EMAIL } from '@/temlplates';
import _ from 'lodash';
import { duplicationEmail } from '@/api/login';
import Countdown from '@/components/login/Countdown.vue';

export default {
	name: 'updateEmailLayer',
	components: { Countdown },
	props: {
		originalEmail: {
			type: String,
			default: '',
		},
	},
	data() {
		return {
			email: this.originalEmail,
			isInvalidEmail: '',
			emailReason: '',
			isCodeVisible: false,
			authCode: '',
			twofactorResult: '',
		};
	},
	methods: {
		async validateDuplicateEmail() {
			if (_.isEmpty(this.email)) {
				return;
			}
			let response = await duplicationEmail(this.email);
			if (response.data) {
				this.isInvalidEmail = true;
				this.emailReason = ALREADY_USED_EMAIL;
			}
		},
		validateEmail(e) {
			if (this.isCodeVisible) {
				this.resetConfirm();
			}
			if (checkEmail(e.currentTarget.value)) {
				this.isInvalidEmail = false;
				this.emailReason = VALID_EMAIL;
			} else {
				this.isInvalidEmail = true;
				this.emailReason = INVALID_EMAIL;
			}
		},
		resetConfirm() {
			this.isCodeVisible = false;
			this.$refs.countdown.resetCountdown();
			this.twofactorResult = '';
		},
		sendEmailAuth() {
			this.validateDuplicateEmail();
			if (this.isInvalidEmail) {
				return;
			}

			let data = {
				oldEmail: this.originalEmail,
				newEmail: this.email,
			};

			let response = sendMailUpdateUserEmail(data);
			response
				.then((response) => {
					if (this.isCodeVisible) {
						this.resetConfirm();
					}
					this.isCodeVisible = true;
					this.$toasted.show('인증코드가 발송되었습니다.');
				})
				.catch((error) => {
					this.$toasted.show('인증코드 발송에 실패했습니다. 잠시후 다시 시도해주세요.');
				});
		},
		handleConfirmEmail() {
			let data = {
				newEmail: this.email,
				authCode: this.authCode,
			};

			let response = this.$store.dispatch('updateUserEmail', data);
			response
				.then((response) => {
					this.handleClose();
				})
				.catch((error) => {
					this.twofactorResult = error.data.errorMessage;
				});
		},
		handleClose() {
			this.$emit('close');
		},
	},
	beforeDestroy() {
		if (this.isCodeVisible) {
			this.$refs.countdown.stopCountdown();
		}
	},
};
</script>

<style scoped></style>
