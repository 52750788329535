<template>
	<div @click="handleClick" class="group-toggle" :class="{ off: !on, sm: isSmall, disabled: disabled }">
		<div v-if="on" class="toggle left">
			<label class="label">{{ isSmall ? '' : '사용' }}</label>
		</div>
		<div v-if="!on" class="toggle right">
			<label class="label">{{ isSmall ? '' : '사용안함' }}</label>
		</div>
		<div class="toggle btn"></div>
	</div>
</template>

<script>
export default {
	name: 'ToggleButton',
	props: {
		on: Boolean,
		isSmall: Boolean,
		disabled: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {};
	},
	methods: {
		handleClick() {
			if (this.disabled) return;
			this.$emit('toggleButton:click');
		},
	},
};
</script>

<style scoped></style>
