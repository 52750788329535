import { fileApply, fileUpload } from '@/api/common';

export const attach = {
	methods: {
		async selectFile(event) {
			if (this.validate(event.target.files[0])) {
				const fileInfo = await this.upload(event.target.files);
				this.setAttaches(fileInfo);
			}
		},
		openFileTypeInput() {
			this.$refs.fileInput.click();
		},
		async upload(file) {
			try {
				const formData = new FormData();
				formData.append('file', file[0]);
				const { data } = await fileUpload(formData);
				return await this.apply(data);
			} catch (e) {
				console.log(e);
			}
		},
		async apply(file) {
			try {
				const { data } = await fileApply(file);
				return {
					attachKey: data.attachKey,
					originFilename: data.originFilename,
					path: data.filePath,
					deletedAt: null,
					extension: data.extension,
					size: data.size,
				};
			} catch (e) {
				console.log(e);
			}
		},
		setAttaches(fileInfo) {
			console.log(fileInfo);
			console.warn('setAttaches 구현해주세요.');
		},
		validate() {
			return true;
		},
	},
};
