<template>
	<!-- 추가정보 수정 -->
	<div>
		<div class="header">
			<!-- // .lay_header -> .header -->
			<h1>추가정보 수정</h1>
			<button class="icon" @click="handleClose">
				<i class="ic-24 ic-close"></i>
				close
			</button>
		</div>
		<!--  contents-wrap  -->
		<div class="contents-wrap">
			<ul class="inp-list">
				<li>
					<div class="group-inp">
						<label class="label">직급</label>
						<input
							type="text"
							class=""
							placeholder="직급을 입력해주세요. ex)인턴, 사원, 차장"
							v-model="newRank"
							maxlength="50"
						/>
					</div>
				</li>
				<li>
					<div class="group row col3 multi-box gap8">
						<div class="group-inp">
							<label for="" class="label">유선전화</label>
							<input
								type="text"
								id=""
								class=""
								oninput="this.value = this.value.replace(/[^0-9]/g, '').replace(/(\..*)\./g, '$1');"
								placeholder="유선전화를 입력해주세요."
								v-model="newTel"
								maxlength="15"
							/>
						</div>
					</div>
				</li>
			</ul>
		</div>
		<div class="btn-area group-btn">
			<button class="line" @click="handleClose">취소</button>
			<button class="solid" @click="handleSaveExtra">저장</button>
		</div>
	</div>
</template>

<script>
export default {
	name: 'updateExtraLayer',
	props: {
		rank: {
			type: String,
			default: '',
		},
		tel: {
			type: String,
			default: '',
		},
	},
	data() {
		return {
			newRank: this.rank,
			newTel: this.tel,
		};
	},
	methods: {
		async handleSaveExtra() {
			const data = {
				rank: this.newRank.trim(),
				tel: this.newTel.trim(),
			};

			try {
				const response = await this.$store.dispatch('updateUserExtra', data);

				this.$toasted.show('저장이 완료되었습니다.');
				this.$emit('close');
			} catch (e) {
				this.$toasted.show('저장에 실패했습니다.');
			}
		},
		handleClose() {
			this.$emit('close');
		},
	},
};
</script>

<style scoped></style>
