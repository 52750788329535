<template>
	<span class="countdown">{{ this.remainTime }}</span>
</template>

<script>
const TIMER = 5 * 60 * 1000;

export default {
	name: 'Countdown',
	data() {
		return {
			timer: TIMER,
		};
	},
	created() {
		this.startCountdown();
	},
	computed: {
		remainTime() {
			let minute = parseInt(this.timer / (60 * 1000));
			let second = (this.timer / 1000) % 60;
			if (second < 10) second = '0' + second;
			return `${minute}:${second}`;
		},
	},
	methods: {
		startCountdown() {
			this.timer = TIMER;
			clearInterval(this.interval);
			this.interval = setInterval(() => {
				if (this.timer > 0) {
					this.timer = this.timer - 1000;
				} else {
					this.$ms.toast.show('시간이 초과하였습니다.');
					this.stopCountdown();
				}
			}, 1000);
		},
		stopCountdown() {
			clearInterval(this.interval);
		},
		resetCountdown() {
			this.startCountdown();
		},
	},
};
</script>

<style scoped></style>
